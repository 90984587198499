// tslint:disable:member-ordering

import {autobind} from 'core-decorators';
import {isNumber} from 'lodash';

import {Model, ApiModelRetriever} from './model';

type ServerLocation = {
  id: number;
  name: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  created_at: Date;
  updated_at: Date;
  country_code: string;
  state: string;
};

@autobind
export default class Location extends Model<ServerLocation> {
  private static retriever = new ApiModelRetriever<ServerLocation, Location>('/api/locations', Location);
  public static find = Location.retriever.fetch;
  public static findAll = Location.retriever.fetchAll;

  public get id(): number {
    return this.data.id;
  }

  public get name(): string {
    return this.data.name;
  }

  public get city(): string {
    return this.data.city;
  }

  public get state(): string {
    return this.data.state;
  }

  public get country(): string {
    return this.data.country;
  }

  public get latitude(): number {
    return this.data.latitude;
  }

  public get longitude(): number {
    return this.data.longitude;
  }

  public get countryCode(): string {
    return this.data.country_code;
  }

  public get hasCoordinates(): boolean {
    return isNumber(this.data.latitude) && isNumber(this.data.longitude);
  }

  public get updatedAt(): Date {
    return new Date(this.data.updated_at);
  }

  public get createdAt(): Date {
    return new Date(this.data.created_at);
  }
}
