import * as React from 'react';
import {autobind} from 'core-decorators';
import {Debounce} from 'lodash-decorators';
import {Icon} from 'react-fa';
import {min} from 'lodash';
import {Button} from 'reactstrap';

@autobind
export default class DateSlider extends React.Component<{
  minDate: Date;
  maxDate: Date;
  value: Date;
  autoAdvance: boolean;
  onChange: (params: {date?: Date, autoAdvance?: boolean}) => void;
}, {
  pendingValue: Date;
}> {
  private autoAdvanceInterval: number;

  constructor(props, context) {
    super(props, context);
    this.state = {
      pendingValue: null
    };
  }

  public render(): React.ReactElement<any> {
    const {minDate, maxDate} = this.props;
    const {autoAdvance} = this.props;
    const date = this.current;

    return (
      <div className='date-slider'>
        <Button className='play-btn' onClick={autoAdvance ? this.pause : this.play}>
          <Icon name={autoAdvance ? 'pause' : 'play'} />
        </Button>
        <span>{minDate.getFullYear()}</span>
        <input
          type='range'
          value={date.getTime()}
          onChange={this.onDateChange}
          min={minDate.getTime()}
          max={maxDate.getTime()}
        />
        <span>{maxDate.getFullYear()}</span>
        <span className='selected-date'>{date.getFullYear()}</span>
      </div>
    );
  }

  public play() {
    if (this.isAtEnd) {
      this.props.onChange({
        date: this.props.minDate,
        autoAdvance: true
      });
    }
    if (!this.autoAdvanceInterval) {
      this.autoAdvanceInterval = window.setInterval(this.autoAdvance, 100);
    }
  }

  public pause() {
    this.props.onChange({
      date: this.props.value,
      autoAdvance: false
    });

    if (this.autoAdvanceInterval) {
      window.clearInterval(this.autoAdvanceInterval);
      this.autoAdvanceInterval = null;
    }
  }

  private autoAdvance() {
    const year = 1000 * 60 * 60 * 24 * 365.26;
    this.advance(year);
    if (this.isAtEnd) {
      this.pause();
    }
  }

  private get isAtEnd(): boolean {
    return this.current.getTime() === this.props.maxDate.getTime();
  }

  private advance(timeMs: number) {
    const timeTillEnd = this.props.maxDate.getTime() - this.current.getTime();
    const advanceTime = min([timeTillEnd, timeMs]);

    if (advanceTime > 0) {
      const newDate = new Date(this.current.getTime() + advanceTime);
      this.props.onChange({date: newDate, autoAdvance: true});
    } else {
      this.pause();
    }
  }

  private get current(): Date {
    return this.state.pendingValue || this.props.value;
  }

  private onDateChange(e: React.ChangeEvent<any>) {
    const value = parseInt(e.target.value, 10);
    this.setState({
      pendingValue: new Date(value)
    }, this.debouncedUpdate);
  }

  @Debounce(250)
  private debouncedUpdate() {
    const {pendingValue} = this.state;
    this.props.onChange({date: pendingValue});
    this.setState({pendingValue: null});
  }
}
