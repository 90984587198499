import * as $ from 'jquery';

let animationTime = 0;

function expandCollapseEventEditCards(form: HTMLFormElement) {
  const checkboxes: JQuery<Element> = $(form).find('.show-event');
  checkboxes.each((i, element) => {
    const checkbox = $(element);
    const eventFields = checkbox.closest('.event-fields').find('.fields-checked');
    checkbox.on('change', () => {
      if (checkbox.is(':checked')) {
        eventFields.show(animationTime);
      } else {
        eventFields.hide(animationTime);
      }
    });

    checkbox.change();
  });

  animationTime = 500;
}

function validateDateFields(form: HTMLFormElement) {
  const dateFields: HTMLElement[] = Array.from(form.querySelectorAll('.date-field'));
  dateFields.forEach((field) => {
    const dateInput = field.querySelector('input[type=date], input[type=number]');
    const dateApproximateCheckbox = field.querySelector('input[type=checkbox]');

    $(dateApproximateCheckbox).on('change', () => {
      // If date is approximate, become a year selector
      if ($(dateApproximateCheckbox).is(':checked')) {
        $(dateInput).attr({
          type: 'number',
          placeholder: 'yyyy',
          min: 0,
          max: 3000,
          step: 1
        });
      } else {
      // Otherwise date selector
        $(dateInput).attr({
          type: 'date',
          min: null,
          max: null
        });
      }
    });

    $(dateApproximateCheckbox).change();
  });
}

function loadForm() {
  const form: HTMLFormElement = document.querySelector('.new_person, .edit_person');

  if (form) {
    expandCollapseEventEditCards(form);
    validateDateFields(form);
  }
}

$(document).on('turbolinks:load', loadForm);
$(document).on('cocoon:after-insert', loadForm);
