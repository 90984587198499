import * as React from 'react';
import * as ReactDOM from 'react-dom';

// React Component
import FamilyTreeGraph from './components/trees/familyTreeGraph';
import AsyncFamilyTree from './components/trees/familyTreeGraph/asyncFamilyTree';
import PeopleExplorer from './components/people/explorer';
import MapDropdown from './components/nav/mapDropdown';
import {SvgGraphLoader} from './components/trees/svgGraph';
import {FamilyHistoryPeopleMap} from './pages';

// Legacy JS
import './legacy';

// Styles
import './app.scss';
import 'leaflet/dist/leaflet.css';

type ReactElementMapping = {
  className: string;
  reactClass: React.ComponentClass;
};

const reactElements: ReactElementMapping[] = [{
  className: 'people-explorer',
  reactClass: PeopleExplorer
}, {
  className: 'map-dropdown',
  reactClass: MapDropdown
}, {
  className: 'family-tree',
  reactClass: FamilyTreeGraph
}, {
  className: 'async-family-tree',
  reactClass: AsyncFamilyTree
}, {
  className: 'family-history-people-map',
  reactClass: FamilyHistoryPeopleMap
}, {
  className: 'svg-graph-loader',
  reactClass: SvgGraphLoader
}];

function loadReactComponents() {
  // Load any react elements rendered by server with special classes
  reactElements.forEach((reactElementMapping: ReactElementMapping) => {
    const {className, reactClass} = reactElementMapping;
    const elements: HTMLElement[] = Array.from(document.body.getElementsByClassName(className)) as any;
    elements.forEach((element: HTMLElement) => {
      let props;
      try {
        props = JSON.parse(element.dataset.props || '{}');
      } catch (e) {
        console.error('Error reading props from element', element);
        return;
      }

      const reactElement = React.createElement(reactClass, props);
      ReactDOM.render(reactElement, element);
    });
  });
}

document.addEventListener('DOMContentLoaded', loadReactComponents);
document.addEventListener('turbolinks:load', loadReactComponents);