import * as React from 'react';
import {Icon} from 'react-fa';

export default class PromiseRenderer<T> extends React.Component<{
  promise: Promise<T>;
  renderer: (data: T) => React.ReactElement<any> | React.ReactElement<any>[];
}, {
  data: T;
  loaded: boolean;
}> {
  private mounted: boolean = false;

  constructor(props, context) {
    super(props, context);
    this.state = {
      data: null,
      loaded: false
    };
  }

  public async componentDidMount() {
    this.mounted = true;
    const data = await this.props.promise;
    if (this.mounted) {
      this.setState({
        data,
        loaded: true
      });
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactElement<any> | React.ReactElement<any>[] {
    const {data, loaded} = this.state;
    const {renderer} = this.props;

    if (data) {
      return renderer(data);
    } else if (!loaded) {
      return <Icon name='spinner' spin={true} />;
    } else {
      return null;
    }
  }
}