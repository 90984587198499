// tslint:disable:member-ordering

import {autobind} from 'core-decorators';

import Event from './event';
import {Model, ApiModelRetriever} from './model';
import Person from './person';

type ServerMarriagePartner = {
  id: number;
  person_id: number;
  created_at: string;
  updated_at: string;
};

export type ServerMarriage = {
  id: number;
  marriage_partners: ServerMarriagePartner[];
  wedding_id: number;
  divorce_id: number;
  created_at: string;
  updated_at: string;
};

@autobind
export default class Marriage extends Model<ServerMarriage> {
  private static retriever = new ApiModelRetriever<ServerMarriage, Marriage>('/api/marriages', Marriage);
  public static find = Marriage.retriever.fetch;
  public static findAll = Marriage.retriever.fetchAll;

  public get id(): number {
    return this.data.id;
  }

  public get wedding(): Promise<Event> {
    if (this.data.wedding_id) {
      return Event.find(this.data.wedding_id);
    }
  }

  public get divorce(): Promise<Event> {
    if (this.data.divorce_id) {
      return Event.find(this.data.divorce_id);
    }
  }

  public get partners(): Promise<Person[]> {
    return Person.findAll(this.data.marriage_partners.map((p) => p.person_id));
  }

  public get updatedAt(): Date {
    return new Date(this.data.updated_at);
  }

  public get createdAt(): Date {
    return new Date(this.data.created_at);
  }
}
