import * as React from 'react';
import {Icon} from 'react-fa';
import {autobind} from 'core-decorators';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

@autobind
export default class MapDropdown extends React.Component<{}, {
  dropdownOpen: boolean;
}> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropdownOpen: false
    };
  }

  public render() {
    return (
      <Dropdown className='map-dropdown' isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret>
          <Icon name='map'/> Maps
        </DropdownToggle>
        <DropdownMenu right={true}>
          <DropdownItem header>Maps</DropdownItem>
          <DropdownItem href='/maps/people'>People</DropdownItem>
          <DropdownItem href='/maps/locations'>Locations</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  private toggle() {
    const {dropdownOpen} = this.state;
    this.setState({dropdownOpen: !dropdownOpen});
  }
}