import * as React from 'react';
import * as moment from 'moment';
import * as Color from 'color';
import {LatLngLiteral, LatLngExpression} from 'leaflet';
import DivIcon from 'react-leaflet-div-icon';
import {Popup, Polyline} from 'react-leaflet';
import {sortBy, last, min, max, some, isNil, flatten} from 'lodash';
import {autobind} from 'core-decorators';

import DateSlider from './dateSlider';
import Location from '../../../model/location';
import {TimelineMapLegend} from './timelineMapLegend';
import AutoScalingMap from '../autoScalingMap';
import { eventMarkerIconImg } from '../mapHelpers';

export type TimelineMapUnit = {
  name: string;
  key: string | number;
  hue: number; // What is the hue rotate for this unit -360 - 360
  icon: React.ReactElement<any>;
  url?: string;
  dateRange: {
    start: Date;
    end: Date;
  };
  events: TimelineMapEvent[]
};

export type TimelineMapEvent = {
  key: string | number;
  title: string;
  date: Date;
  location: Location;
};

type TimelineMapProps = {
  mapUnits: TimelineMapUnit[];
};

export default class TimelineMap extends React.Component<TimelineMapProps, {
  date: Date;
  autoAdvance: boolean;
}> {
  constructor(props: TimelineMapProps, context) {
    super(props, context);
    this.state = {
      date: this.maxDateFromMapUnits(props.mapUnits),
      autoAdvance: false
    };
  }

  public render() {
    const {date, autoAdvance} = this.state;

    if (this.props.mapUnits.length === 0) {
      return null;
    }

    const dateSlider = this.props.mapUnits.length > 0
      ? (
        <DateSlider
          value={date}
          minDate={this.minDate}
          maxDate={this.maxDate}
          autoAdvance={autoAdvance}
          onChange={this.onDateChange}
        />
      )
      : null;

    return (
      <div className='timeline-map-container'>
        <div className='map-container'>
          <AutoScalingMap locations={this.locations}>
            {this.lines(date)}
            {this.eventMarkers(date)}
            {this.unitMarkers(date)}
          </AutoScalingMap>
          <TimelineMapLegend activeMapUnits={this.activeMapUnits(date)}/>
        </div>
        <div>
          {dateSlider}
        </div>
      </div>
    );
  }

  private get events(): TimelineMapEvent[] {
    return flatten(this.props.mapUnits.map((u) => u.events).filter(Boolean));
  }

  private get locations(): Location[] {
    return this.events.map((e) => e.location).filter((l) => l && l.hasCoordinates);
  }

  private get endOfToday(): Date {
    return moment().endOf('day').toDate();
  }

  private get minDate(): Date {
    const {mapUnits} = this.props;
    const startDates = mapUnits.map((u) => u.dateRange.start).filter(Boolean);
    return min(startDates) || this.maxDate;
  }

  private get maxDate(): Date {
    return this.maxDateFromMapUnits(this.props.mapUnits);
  }

  private maxDateFromMapUnits(mapUnits: TimelineMapUnit[]) {
    const endDates: Date[] = mapUnits.map((u) => u.dateRange.end);
    if (some(endDates, isNil) || endDates.length === 0) {
      return this.endOfToday;
    } else {
      return max(endDates);
    }
  }

  @autobind
  private onDateChange(params: {date?: Date, autoAdvance?: boolean}) {
    this.setState(params as any);
  }

  private activeMapUnits(date: Date): TimelineMapUnit[] {
    return this.props.mapUnits.filter((unit: TimelineMapUnit) => {
      const {dateRange} = unit;
      const {start, end} = dateRange;
      const hasValidEvents = unit.events.filter((e) => e.date && e.location).length > 0;
      const afterStart = !start || date >= start;
      const beforeEnd = !end || date <= end;
      return hasValidEvents && afterStart && beforeEnd;
    });
  }

  private unitMarkers(date: Date): React.ReactElement<any>[] {
    return this.activeMapUnits(date).map((unit: TimelineMapUnit) => {
      const eventsWithLocation = unit.events.filter((e) => e.location && e.date);
      const events: TimelineMapEvent[] = sortBy(eventsWithLocation, (e) => e.date);
      const lastKnownEvent = last(events.filter((e) => e.date <= date)) || last(events);

      return this.marker({
        event: lastKnownEvent,
        unit,
        icon: unit.icon,
        key: unit.key
      });
    });
  }

  private eventMarkers(date: Date): React.ReactElement<any>[] {
    if (this.state.autoAdvance) {
      return [];
    }

    return flatten(this.activeMapUnits(date).map((unit: TimelineMapUnit) => {
      const eventsWithLocation = unit.events.filter((e) => e.location && e.date);
      const events: TimelineMapEvent[] = sortBy(eventsWithLocation, (e) => e.date);
      const lastKnownEvent = last(events.filter((e) => e.date <= date)) || last(events);

      return events.filter((event) => event !== lastKnownEvent).map((event: TimelineMapEvent, i: number) => (
        this.marker({
          event,
          icon: eventMarkerIconImg,
          unit,
          key: `${unit.key}-${event.key}`
        })
      ));
    }));
  }

  private marker(params: {
    icon: React.ReactElement<any>;
    unit: TimelineMapUnit;
    event: TimelineMapEvent;
    key: string | number;
  }): React.ReactElement<any> {
    const {event, unit, key, icon} = params;
    const {hue, name, url} = unit;
    const location: Location = event.location;

    const title = url
      ? <a href={url}>{name}</a>
      : name;

    const position: LatLngLiteral = {
      lat: location.latitude,
      lng: location.longitude
    };

    return (
      <DivIcon key={key} position={position} >
        <div style={{filter: `hue-rotate(${hue}deg`}}>
          {icon}
        </div>
        <Popup>
          <div>
            <strong>{title}</strong>
            <div>
              {event.title} {event.date.toLocaleDateString()}
              <br/>
              <span className='text-muted'>
                {location.name}
              </span>
            </div>
          </div>
        </Popup>
      </DivIcon>
    );
  }

  private lines(date: Date): React.ReactElement<any>[] {
    if (this.state.autoAdvance) {
      return null;
    }

    return this.activeMapUnits(date).map((mapUnit) => {
      const locations = mapUnit.events.map((e) => e.location).filter(Boolean).filter((l) => l.hasCoordinates);

      const color = Color('#4597d0').rotate(mapUnit.hue);

      const positions: LatLngExpression[] = locations.map((loc) => (
        [loc.latitude, loc.longitude] as LatLngExpression
      ));

      return (
        <Polyline
          key={`${mapUnit.key}-line`}
          positions={positions}
          lineCap='round'
          color={color.hex()}
        />
      );
    });
  }
}
