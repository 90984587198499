import {autobind} from 'core-decorators';

import {fetchObject} from './model';

export type IFamilyTree = DescendentTree & AncestryTree;

export type DescendentTree = {
  name: string;
  id: number;
  url: string;
  partners: Partnership[];
};

export type Partnership = {
  name: string;
  partnership: true;
  partner: {
    name: string;
    id: number;
    url: string;
    partnerNum: number;
  };
  children: DescendentTree[];
};

export type AncestryTree = {
  name: string;
  url: string;
  id: number;
  parents: AncestryTree[];
};

@autobind
export default class FamilyTree {
  public static find = fetchObject<IFamilyTree>((id) => `/api/people/${id}/family_tree`);
}
