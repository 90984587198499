import Person from '../../../model/person';

export interface Node {
  key: string;
  type: NodeType;
}

export enum NodeType {
  Person = 'Person',
  Partnership = 'Partnership'
}

export interface PersonNode extends Node {
  name: string;
  type: NodeType.Person;
  parents: PartnershipNode;
  partnerships: PartnershipNode[];
}

export interface PartnershipNode extends Node {
  mother: PersonNode;
  type: NodeType.Partnership;
  father: PersonNode;
  children: PersonNode[];
}

function randomString(): string {
  return Math.random().toString(36).substring(7);
}

// Converts an array of people into a set of linked Nodes
export class Graph {
  public readonly personNodes = new Map<number, PersonNode>();
  public readonly partnershipNodes = new Map<string, PartnershipNode>();

  constructor(people: Person[]) {
    // Create person nodes
    people.forEach((person: Person) => {
      this.personNodes.set(person.id, {
        key: person.id.toString(),
        type: NodeType.Person,
        name: person.name,
        parents: null,
        partnerships: []
      });
    });

    // Create parent records
    people.forEach((person: Person) => {
      const parentKey = `${person.fatherId || randomString()}-${person.motherId || randomString()}`;

      // Create partnership if it doesn't already exist
      if (!this.partnershipNodes.has(parentKey)) {
        const mother: PersonNode = this.personNodes.get(person.motherId);
        const father: PersonNode = this.personNodes.get(person.fatherId);
        const partnership: PartnershipNode = {
          key: parentKey,
          type: NodeType.Partnership,
          mother: mother,
          father: father,
          children: []
        };
        if (mother) {
          mother.partnerships.push(partnership);
        }
        if (father) {
          father.partnerships.push(partnership);
        }
        this.partnershipNodes.set(parentKey, partnership);
      }

      const partnership: PartnershipNode = this.partnershipNodes.get(parentKey);
      const personNode: PersonNode = this.personNodes.get(person.id);

      // Link children to partnership
      personNode.parents = partnership;
      partnership.children.push(personNode);
    });
  }
}