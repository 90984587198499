// tslint:disable:max-line-length
import * as React from 'react';
import markerIconImageUrl from 'leaflet/dist/images/marker-icon.png';
import eventMarkerIconImgUrl from './event-marker.png';
import {Icon} from 'leaflet';
import {TileLayer} from 'react-leaflet';

export const defaultMarkerIcon: Icon = new Icon({
  iconUrl: markerIconImageUrl,
  iconSize: [12, 18]
});

export const defaultMarkerIconImg = (
  <img
    height={24}
    width={16}
    src={markerIconImageUrl}
    style={{marginTop: '-32px', marginLeft: '-2px'}}
  />
);

export const eventMarkerIconImg = (
  <img
    height={12}
    width={12}
    src={eventMarkerIconImgUrl}
    style={{marginTop: '-6px', opacity: .8}}
  />
);

enum OpenStreetMapTheme {
  default = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  blackWhite = 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
  waterColor = 'http://{s}.tile.stamen.com/watercolor/{z}/{x}/{y}.jpg'
}

export const mapLayer: React.ReactElement<any> = (
  <TileLayer
    url={OpenStreetMapTheme.waterColor}
  />
);
