export class Deferred<T> implements Promise<T> {
  private readonly _promise: Promise<T>;
  private _resolve: (value?: T | PromiseLike<T>) => void;
  private _reject: (reason?: any) => void;
  private _pending: boolean = true;

  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
    this._promise.finally(() => {
      this._pending = false;
    });
  }

  public then(callback: (value: T) => any) {
    return this._promise.then(callback);
  }

  public catch(callback: (err: Error) => any) {
    return this._promise.catch(callback);
  }

  public finally(callback: () => void) {
    return this._promise.finally(callback);
  }

  public resolve(value?: T | PromiseLike<T>): void {
    this._resolve(value);
  };

  public reject(reason?: any): void {
    this._reject(reason);
  };

  public get pending(): boolean {
    return this._pending;
  }

  public get [Symbol.toStringTag]() {
    return this._promise[Symbol.toStringTag];
  }
}