import * as React from 'react';
import {uniqBy, flatten, uniq, compact, isEqual} from 'lodash';
import {Icon} from 'react-fa';

import PeopleMap, {MappablePerson, MappableEvent} from './peopleMap';
import Person from '../../../model/person';
import Event from '../../../model/event';
import Location from '../../../model/location';
import {PeopleMapTour} from './peopleMapTour';

type AsyncPeopleMapProps = {
  people: Person[];
};

interface AsyncPeopleMapState {
  mappablePeople: MappablePerson[];
  loading: boolean;
}

export default class AsyncPeopleMap extends React.Component<AsyncPeopleMapProps, AsyncPeopleMapState> {
  constructor(props: AsyncPeopleMapProps, context) {
    super(props, context);
    this.state = {
      mappablePeople: null,
      loading: true
    };
  }

  public componentDidMount() {
    this.generateMappablePeople(this.props.people);
  }

  public componentDidUpdate(prevProps: AsyncPeopleMapProps) {
    if (!isEqual(prevProps, this.props)) {
      this.generateMappablePeople(this.props.people);
    }
  }

  public render(): React.ReactElement<any> {
    const {mappablePeople} = this.state;

    if (mappablePeople) {
      return (
        <div className='async-people-map'>
          {this.loadingIndicator}
          {this.state.loading ? null : <PeopleMapTour/>}
          <PeopleMap people={mappablePeople}/>
        </div>
      );
    } else {
      return null;
    }
  }

  private get loadingIndicator(): React.ReactElement<any> {
    if (this.state.loading) {
      return (
        <div className='loading-indicator'>
          <Icon name='spinner' spin={true}/>
          {' '}
          Loading family data...
        </div>
      );
    }
  }

  private async generateMappablePeople(people: Person[]) {
    this.setState({loading: true});

    // Pre-load events
    const eventIds = flatten(people.map((p) => p.eventIds));
    const allEvents: Event[] = await Event.findAll(compact(uniq(eventIds)));

    // Pre-load locations
    const locationIds = allEvents.map((e) => e.locationId);
    await Location.findAll(compact(uniq(locationIds)));

    const uniquePeople = uniqBy(people, (p) => p.id);
    const mappablePeople: MappablePerson[] = await Promise.all(uniquePeople.map(async (person: Person) => {
      const birth: MappableEvent = await this.generateMappableEvent(await person.birth);
      const death: MappableEvent = await this.generateMappableEvent(await person.death);
      const eventModels: Event[] = await Promise.all(person.eventIds.map(Event.find));
      const events: MappableEvent[] = await Promise.all(eventModels.map(this.generateMappableEvent));

      return {
        id: person.id,
        name: person.name,
        birth,
        death,
        events
      };
    }));

    this.setState({
      mappablePeople,
      loading: false
    });
  }

  private async generateMappableEvent(event: Event): Promise<MappableEvent> {
    if (event) {
      const location = await event.location;

      return {
        key: event.id,
        verb: event.description,
        date: event.date,
        location
      };
    }
  }
}