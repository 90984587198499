// tslint:disable:member-ordering

import {autobind} from 'core-decorators';
import * as moment from 'moment';
import {flatten, compact, uniq, uniqBy, sortBy, last} from 'lodash';

import Event from './event';
import Gender from './gender';
import {Model, fetchObject, ApiModelRetriever} from './model';
import FamilyTree, {IFamilyTree, AncestryTree, DescendentTree} from './familyTree';
import Image from './image';
import Document from './document';
import Location from './location';
import Marriage from './marriage';

export type ServerPerson = {
  id: number;
  name: string;
  preferred_name: string;
  aliases: string[];
  notes: string;
  occupation: string;
  birth_id: number;
  death_id: number;
  event_ids: number[];
  gender_cd: number;
  gedcom_id: number;
  father_id: number;
  mother_id: number;
  marriage_ids: number[];
  created_at: number;
  updated_at: number;
};

export type Heritage = {
  [country: string]: number
};

@autobind
export default class Person extends Model<ServerPerson> {
  private static retriever = new ApiModelRetriever<ServerPerson, Person>('/api/people', Person);
  public static find = Person.retriever.fetch;
  public static findAll = Person.retriever.fetchAll;
  public static relation = fetchObject<string>((id) => `/api/people/${id}/relation.json`);
  public static heritage = fetchObject<Heritage>((id) => `/api/people/${id}/heritage.json`);
  public static siblings = fetchObject<number[]>((id) => `/api/people/${id}/siblings.json`);
  public static halfSiblings = fetchObject<number[]>((id) => `/api/people/${id}/half_siblings.json`);
  public static children = fetchObject<number[]>((id) => `/api/people/${id}/children.json`);
  public static images = fetchObject<number[]>((id) => `/api/people/${id}/images.json`);
  public static documents = fetchObject<number[]>((id) => `/api/people/${id}/documents.json`);
  public static me = () => Person.find('me' as any);

  // Received props
  public get id(): number {
    return this.data.id;
  }

  public get name(): string {
    return this.data.name;
  }

  public get aliases(): string[] {
    return this.data.aliases;
  }

  public get preferredName(): string {
    return this.data.preferred_name;
  }

  public get notes(): string {
    return this.data.notes;
  }

  public get occupation(): string {
    return this.data.occupation;
  }

  public get fatherId(): number {
    return this.data.father_id;
  }

  public get motherId(): number {
    return this.data.mother_id;
  }

  public get father(): Promise<Person> {
    return Person.find(this.data.father_id);
  }

  public get mother(): Promise<Person> {
    return Person.find(this.data.mother_id);
  }

  public async children(): Promise<Person[]> {
    const childrenIds: number[] = await Person.children(this.id);
    return Person.findAll(childrenIds);
  }

  public async siblings(): Promise<Person[]> {
    const siblingIds: number[] = await Person.siblings(this.id);
    return Person.findAll(siblingIds);
  }

  public async halfSiblings(): Promise<Person[]> {
    const siblingIds: number[] = await Person.halfSiblings(this.id);
    return Person.findAll(siblingIds);
  }

  public async currentSpouse(): Promise<Person> {
    const marriages: Marriage[] = await this.marriages;
    const noDivorce = marriages.filter((m) => !m.divorce);

    // If no divorce, there's still a possibility the person
    // re-married after a death

    const records: {
      partner: Person;
      death: Event;
      wedding: Event;
    }[] = flatten(
      await Promise.all(noDivorce.map(async (m) => {
        const wedding: Event = await m.wedding;
        const partner: Person = (await m.partners).filter((p) =>  p.id !== this.id)[0];
        const death: Event = partner ? await partner.death : null;

        return {
          partner,
          death,
          wedding
        };
      }))
    ).filter((r) => r.partner);

    if (records.length === 0) {
      return null;
    } else if (records.every((r) => Boolean(r.wedding && r.wedding.date))) {
      // If weddings have dates, return most recent
      return last(sortBy(records, (r) => r.wedding.date)).partner;
    } else {
      // Otherwise, sort by death, return the latest death
      return last(sortBy(records, (r) => {
        if (r.death && r.death.date) {
          return r.death.date;
        } else {
          return new Date();
        }
      })).partner;
    }
  }

  public async formerSpouses(): Promise<Person[]> {
    const spouses: Person[] = await this.spouses();
    const currentSpouse = await this.currentSpouse();
    const currentSpouseId = currentSpouse ? currentSpouse.id : null;

    return spouses.filter((s) => s.id !== currentSpouseId);
  }

  public async spouses(): Promise<Person[]> {
    const marriages: Marriage[] = await this.marriages;
    const partners: Person[] = flatten(
      await Promise.all(marriages.map(async (m) => await m.partners))
    );

    return partners.filter((p) => p.id !== this.id);
  }

  public get marriages(): Promise<Marriage[]> {
    return Marriage.findAll(this.data.marriage_ids);
  }

  public get birth(): Promise<Event> {
    if (this.data.birth_id) {
      return Event.find(this.data.birth_id);
    }
  }

  public get death(): Promise<Event> {
    if (this.data.death_id) {
      return Event.find(this.data.death_id);
    }
  }

  public get eventIds(): number[] {
    return this.data.event_ids;
  }

  public async events(): Promise<Event[]> {
    const events = await Event.findAll(this.data.event_ids);

    // Cache locations
    await Location.findAll(uniq(compact(events.map((e) => e.locationId))));

    // Sort events
    const sortedEvents: Event[] = sortBy(events, (event: Event): number => {
      if (event.type === 'Birth' && event.personId === this.id) {
        return Number.MIN_SAFE_INTEGER;
      } else if (event.type === 'Death' && event.personId === this.id) {
        return Number.MAX_SAFE_INTEGER;
      } else if (event.date) {
        return event.date.getTime();
      }
    });

    return sortedEvents;
  }

  public get gender(): Gender {
    if (this.data.gender_cd === 0) {
      return Gender.male;
    } else if (this.data.gender_cd === 1) {
      return Gender.female;
    }
  }

  public get updatedAt(): Date {
    return new Date(this.data.updated_at);
  }

  public get createdAt(): Date {
    return new Date(this.data.created_at);
  }

  // Fields fetched from different API endpoint

  public get relation(): Promise<string> {
    return Person.relation(this.id);
  }

  public get heritage(): Promise<Heritage> {
    return Person.heritage(this.id);
  }

  // Computed fields

  public async age(): Promise<number> {
    const birth: Event = await this.birth;
    const death: Event = await this.death;

    if (birth && birth.date) {
      const endDate = (death && death.date) || new Date();
      return moment(endDate).diff(birth.date, 'years');
    }
  }

  public async lifespan(): Promise<string> {
    const birth: Event = await this.birth;
    const death: Event = await this.death;
    const age: number = await this.age();

    if (birth && birth.date) {
      if (death) {
        if (death.date) {
          return `${birth.date.getFullYear()} – ${death.date.getFullYear()} (${age} years)`;
        } else {
          return `${birth.date.getFullYear()} – ? (deceased)`;
        }
      } else {
        return `Born in ${birth.date.getFullYear()} (${age} years)`;
      }
    } else if (death && death.date) {
      return `Passed in ${death.date.getFullYear()}`;
    }
  }

  public async familyTree(): Promise<IFamilyTree> {
    return await FamilyTree.find(this.id);
  }

  // Degrees removed:
  // 0 shows only direct descendents and ancestors
  // 1 includes siblings
  // 2 includes cousins
  public async family(degreesRemoved: number): Promise<Person[]> {
    const familyTree: IFamilyTree = await this.familyTree();

    const ancestorIds: number[] = getAncestorIds(familyTree);
    const ancestors: Person[] = await Person.findAll(ancestorIds);

    const descendentIds: number[] = getDescendentIds(familyTree);
    const descendents: Person[] = await Person.findAll(descendentIds);

    const father = await this.father;
    const mother = await this.mother;
    const extendedFamily: Person[] = degreesRemoved > 0
      ? flatten([
        father ? await (father.family(degreesRemoved - 1)) : [],
        mother ? await (mother.family(degreesRemoved - 1)) : []
      ])
      : [];

    const spouses = await this.spouses();

    return uniqBy([
      ...ancestors,
      ...descendents,
      ...extendedFamily,
      ...spouses
    ], (p) => p.id);
  }

  public async images(): Promise<Image[]> {
    const imageIds: number[] = await Person.images(this.id);
    return await Promise.all(imageIds.map(Image.find));
  }

  public async documents(): Promise<Document[]> {
    const documentIds: number[] = await Person.documents(this.id);
    return await Promise.all(documentIds.map(Document.find));
  }
}

function getAncestorIds(tree: AncestryTree): number[] {
  return [
    tree.id,
    ...flatten(tree.parents.map(getAncestorIds))
  ].filter(Boolean);
}

function getDescendentIds(tree: DescendentTree): number[] {
  const children: DescendentTree[] = flatten(tree.partners.map((p) => p.children));

  return [
    tree.id,
    ...flatten(children.map(getDescendentIds))
  ].filter(Boolean);
}