import * as React from 'react';
import {LatLngExpression, Map as LeafletElement} from 'leaflet';
import {Map} from 'react-leaflet';
import {mean} from 'lodash';
import {autobind} from 'core-decorators';

import {mapLayer} from '../mapHelpers';
import Location from '../../../model/location';

interface AutoScalingMapProps {
  locations: Location[];
}

interface AutoScalingMapState {
  height: number;
  center: LatLngExpression;
}

export default class AutoScalingMap extends React.Component<AutoScalingMapProps, AutoScalingMapState> {
  private mapContainer: HTMLElement;
  private leafletElement: LeafletElement;

  public state: AutoScalingMapState = {
    height: null,
    center: [39.8, -98.5]
  };

  public componentDidMount() {
    window.addEventListener('resize', this.updateHeight);
    this.updateCenter(this.props.locations);
  }

  public componentDidUpdate(prevProps: AutoScalingMapProps) {
    if (!this.state.center) {
      this.updateCenter(this.props.locations);
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  public render() {
    const {height, center} = this.state;

    const map = height
      ? (
        <Map
          zoom={2}
          center={center || [39.8, -98.5]}
          style={{height}}
          ref={this.mapElementRef}
        >
          {mapLayer}
          {this.props.children}
        </Map>
      )
      : null;

    return (
      <div className='auto-scaling-map' ref={this.mapContainerRef}>
        {map}
      </div>
    );
  }

  @autobind
  private fitToBounds() {
    const locations = this.props.locations.filter((l) => l.hasCoordinates);

    if (this.leafletElement && locations.length) {
      const tuples: any = locations.map((l) => [
        l.latitude,
        l.longitude
      ]);

      this.leafletElement.fitBounds(tuples, {
        maxZoom: 7
      });
    }
  }

  private updateCenter(locations: Location[]): void {
    if (locations.length) {
      const center: LatLngExpression = [
        mean(locations.map((l) => l.latitude)),
        mean(locations.map((l) => l.longitude))
      ];

      this.setState({center}, this.fitToBounds);
    }
  }

  @autobind
  private mapContainerRef(mapContainer: HTMLElement) {
    this.mapContainer = mapContainer;
    this.updateHeight();
  }

  @autobind
  private mapElementRef(reactLeafletMap: Map) {
    if (reactLeafletMap) {
      this.leafletElement = reactLeafletMap.leafletElement;
    }
  }

  @autobind
  private updateHeight() {
    if (this.mapContainer) {
      const height = this.mapContainer.offsetHeight;
      this.setState({height});
    }
  }
}
