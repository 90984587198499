// tslint:disable
import * as $ from 'jquery';

const addImageFieldListener = function() {
  $('#images input[type=file]').on('change', function(event: any) {
    var files = event.target.files;
    var image = files[0]
    var field = this;
    var form = $(field).closest('form');

    // Populate file name
    var filename = ($(field).val() as string).split("\\").pop().split("/").pop().replace(/\.[A-Za-z0-9]+$/, '');
    var nameInput: JQuery<HTMLInputElement> = $(this).closest('.card').find('input[type=text]') as any;
    if ((nameInput.val() as string).length == 0) {
      nameInput.val(filename);
    }

    // Image preview
    var reader = new FileReader();
    reader.onload = function(file: any) {
      $(field).hide();
      $(field).closest('.card-block').css('background-image', "url('" + file.target.result + "')");
    }
    reader.readAsDataURL(image);

    // Focus on save button
    form.find('input[name=commit]').focus();
  });
}

$(document).on('cocoon:after-insert', addImageFieldListener);
