import * as React from 'react';
import * as moment from 'moment';
import {autobind} from 'core-decorators';
import {Row, Col} from 'reactstrap';
import {isFunction, map, round, isEqual} from 'lodash';
import * as ReactMarkdown from 'react-markdown';
import {Icon} from 'react-fa';

import Person, {Heritage} from '../../../model/person';
import Event from '../../../model/event';
import Location from '../../../model/location';
import PromiseRenderer from '../../util/promiseRenderer';
import FlagIcon, {FlagIconCountries} from '../../util/flagIcon';
import {AsyncPeopleMap} from '../../maps/peopleMap';
import AsyncFamilyTree from '../../trees/familyTreeGraph/asyncFamilyTree';
import Image from '../../../model/image';
import Document from '../../../model/document';
import { SvgGraph } from '../../trees/svgGraph/svgGraph';

interface PeopleExplorerProps {
  personId: number;
}

interface PeopleExplorerState {
  person: Person;
}

@autobind
export default class PeopleExplorer extends React.Component<PeopleExplorerProps, PeopleExplorerState> {
  public state: PeopleExplorerState = {
    person: null
  };

  public async componentDidMount() {
    const person = await Person.find(this.props.personId);
    this.setState({person});
  }

  public async componentDidUpdate(prevProps: PeopleExplorerProps) {
    if(!isEqual(this.props, prevProps)) {
      const person = await Person.find(this.props.personId);
      this.setState({person});
    }
  }

  public render() {
    const {person} = this.state;

    if (person) {
      return (
        <div className='people-explorer' key={person.id}>
          <Row>
            <Col md={6} xs={12}>
              <h1>
                {person.name}
              </h1>

              <PromiseRenderer promise={person.lifespan()} renderer={this.renderLifespan}/>
              <Row>
                {this.renderAliases()}
                {this.renderPreferredName()}
              </Row>

              <h3>Family</h3>
              <dl>
                <Row>
                  <Col md={6} xs={12}>
                    {this.promiseSection('Relation', person.relation, this.renderRelation)}
                  </Col>
                  <Col md={6} xs={12}>
                    {this.promiseSection(this.spouseTitle, person.currentSpouse(), this.renderPersonLink)}
                    {this.promiseArraySection('Former Spouse', person.formerSpouses(), this.renderPersonLink)}
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12}>
                    {this.promiseSection('Father', person.father, this.renderPersonLink)}
                  </Col>
                  <Col md={6} xs={12}>
                    {this.promiseSection('Mother', person.mother, this.renderPersonLink)}
                  </Col>
                </Row>
                <Row>
                <Col md={6} xs={12}>
                    {this.promiseArraySection('Siblings', person.siblings(), this.renderPersonLink)}
                    {this.promiseArraySection('Half-Siblings', person.halfSiblings(), this.renderPersonLink)}
                  </Col>
                  <Col md={6} xs={12}>
                    {this.promiseArraySection('Children', person.children(), this.renderPersonLink)}
                  </Col>
                </Row>
              </dl>

              <dl>
                {this.promiseArraySection(<h3 key='timeline'>Timeline</h3>, person.events(), this.renderEvent)}
              </dl>

              <dl>
                {this.promiseSection('Heritage', person.heritage, this.renderHeritage)}
              </dl>

              <dl>
                {this.renderNotes(person.notes)}
              </dl>
            </Col>
            <Col md={6} xs={12}>
              <div className='section map d-none d-md-block'>
                <AsyncPeopleMap people={[person]}/>
              </div>
              <PromiseRenderer promise={person.images()} renderer={this.renderImages}/>
              <PromiseRenderer promise={person.documents()} renderer={this.renderDocuments}/>
              <div className='section tree d-none d-md-block'>
                <a href={`/people/${person.id}/tree`}>
                  <Icon name='expand' className='fullscreen-btn'/>
                </a>
                <AsyncFamilyTree personId={person.id} onClickNode={this.selectPerson} />
              </div>
            </Col>
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }

  private async selectPerson(personId: number) {
    if (personId !== this.state.person.id) {
      // const person = await Person.find(personId);
      // this.setState({person});
      location.href = `/people/${personId}`;
    }
  }

  private promiseSection<T>(
    title: string | ((value: T) => string),
    promise: Promise<T>,
    renderer: (T) => React.ReactElement<any>
  ): React.ReactElement<any> {
    const rendererWithTitle = (value: T) => {
      const renderedTitle = isFunction(title)
        ? title(value)
        : title;

      return ([
        <dt key='title'>{renderedTitle}</dt>,
        <dd key='value'>{renderer(value)}</dd>
      ]);
    };

    return <PromiseRenderer promise={promise} renderer={rendererWithTitle}/>;
  }

  // tslint:disable-next-line:no-unused-variable
  private promiseGroupSection<T>(
    title: string,
    promises: Promise<T>[],
    renderer: (T) => React.ReactElement<any>
  ): React.ReactElement<any>[] {
    if (promises.length) {
      return ([
        <h3 key='title'>{title}</h3>,
        ...promises.map((promise, i) => (
          <dd key={i}>
            <PromiseRenderer promise={promise} renderer={renderer}/>
          </dd>
        ))
      ]);
    } else {
      return null;
    }
  }

  private promiseArraySection<T>(
    title: string | React.ReactElement<any>,
    promise: Promise<T[]>,
    elementRenderer: (T) => React.ReactElement<any>
  ): React.ReactElement<any> {
    const arrayRenderer = (values: T[]) => {
      const elements: React.ReactElement<any>[] = values.map((val: T, i: number) => (
        <dd key={i}>
          {elementRenderer(val)}
        </dd>
      ));

      const titleElement: React.ReactElement<any> = typeof title === 'string'
        ? <dt key='title'>{title}</dt>
        : title;

      if (values.length) {
        return [
          titleElement,
          ...elements
        ];
      } else {
        return null;
      }
    };

    return <PromiseRenderer promise={promise} renderer={arrayRenderer}/>;
  }

  private spouseTitle(person: Person) {
    switch (person.gender) {
      case('male'):
        return 'Husband';
      case('female'):
        return 'Wife';
      default:
        return 'Spouse';
    }
  }

  private renderPersonLink(person: Person): React.ReactElement<any> {
    // const onClick = (e: React.MouseEvent<any>) => {
    //   e.preventDefault();
    //   this.setState({person});
    // };

    return (
      <a href={`/people/${person.id}`}>{person.name}</a>
    );
  }

  private renderLifespan(lifespan: string): React.ReactElement<any> {
    return (
      <h3 className='text-muted'>
        {lifespan}
      </h3>
    );
  }

  private renderAliases() {
    const aliases = this.state.person.aliases;

    if (aliases.length) {
      return (
        <Col md={6} xs={12}>
          <h3>Aliases</h3>
          <ul className='list-unstyled'>
            {aliases.map((alias: string, i: number) => (
              <li key={i}>{alias}</li>
            ))}
          </ul>
        </Col>
      );
    } else {
      return null;
    }
  }

  private renderPreferredName() {
    const preferredName = this.state.person.preferredName;

    if(preferredName) {
      return (
        <Col md={6} xs={12}>
          <h3>Preferred Name</h3>
          <ul className='list-unstyled'>
            <li>{preferredName}</li>
          </ul>
        </Col>
      );
    } else {
      return null;
    }
  }

  private renderEvent(event: Event): React.ReactElement<any> {
    const date = event.date
      ? event.dateApproximate ? `~${event.date.getFullYear()}` : moment(event.date).format('MMM D, YYYY')
      : null;

    const description = event.personId === this.props.personId
      ? event.verb
      : event.description;

    return (
      <dl>
        <dt>{date} — {description}</dt>
        <dd><PromiseRenderer promise={event.location} renderer={this.renderLocation}/></dd>
      </dl>
    );
  }

  private renderLocation(location: Location): React.ReactElement<any> {
    const countryCode = location.countryCode ? location.countryCode.toLowerCase() : null;
    const flagIcon = countryCode && FlagIconCountries.indexOf(countryCode) >= 0
      ? <FlagIcon code={countryCode}/>
      : null;

    return (
      <span>
        {flagIcon}
        {' '}
        {location.name}
      </span>
    );
  }

  private renderRelation(relation: string): React.ReactElement<any> {
    const parts = relation.split(' ').map((text, i) => {
      const subParts = text.split('^');

      if (subParts[1]) {
        return (
          <span key={i}>
            {subParts[0]}
            <sup key={i}>{subParts[1]}</sup>
            {' '}
          </span>
        );
      } else {
        return (
          <span key={i}>
            {text}
            {' '}
          </span>
        );
      }
    });

    return (
      <span>
        Your {parts}
      </span>
    );
  }

  private renderHeritage(heritage: Heritage): React.ReactElement<any> {
    const items = map(heritage, (percent: number, country: string) => (
      <li key={country}>
        {country}: {round(percent * 100, 2)}%
      </li>
    ));

    return (
      <ul>
        {items}
      </ul>
    );
  }

  private renderImages(images: Image[]): React.ReactElement<any> {
    return (
      <div className='section row'>
        {images.map(this.renderImageCard)}
      </div>
    );
  }

  private renderImageCard(image: Image): React.ReactElement<any> {
    const imageStyle = {
      backgroundImage: `url('${image.mediumImageUrl}')`
    };

    return (
      <div key={image.id} className='col-6 col-lg-4'>
        <a href={`/images/${image.id}`}>
          <div className='card image'>
            <div className='card-header'>
              {image.name}
            </div>
            <div className='card-block' style={imageStyle}/>
          </div>
        </a>
      </div>
    );
  }

  private renderDocuments(documents: Document[]) {
    if (documents.length === 0) {
      return null;
    }

    return (
      <div className='section'>
        <dl>
          <dt>Documents</dt>
          {documents.map(this.renderDocument)}
        </dl>
      </div>
    );
  }

  private renderDocument(document: Document): React.ReactElement<any> {
    return (
      <a key={document.id} href={document.url}>
        {document.name}
        <br/>
        <pre>
          {document.fileName}
        </pre>
      </a>
    );
  }

  private renderNotes(notes: string) {
    if (notes && notes.length) {
      return ([
        <h3 key='notes-title'>Notes</h3>,
        (
          <ReactMarkdown
            key='notes-element'
            source={notes}
            transformLinkUri={null}
          />
        )
      ]);
    }
  }
}
