import Axios, {AxiosResponse} from 'axios';
import {isNil, isEmpty, omitBy, uniq} from 'lodash';
import {autobind} from 'core-decorators';
import {Deferred} from '../util/deferred';

interface ModelClass<ServerType, ClientType extends Model<ServerType>> {
  new (props: ServerType): ClientType;
}

export function fetchObject<T>(endpoint: (id) => string): (id) => Promise<T> {
  const retriever = new ApiObjectRetriever<T>(endpoint);
  return retriever.fetch;
}

export type SearchArgs<ServerType> = {
  [Field in keyof ServerType]?: ServerType[Field] | ServerType[Field][];
};

class ApiObjectRetriever<T> {
  private endpoint: (id: number) => string;
  private cache = new Map<number, T>();

  constructor(endpoint: (id) => string) {
    this.endpoint = endpoint;
  }

  @autobind
  public async fetch(id: number): Promise<T> {
    if (isNil(id)) {
      return null;
    }

    if (!this.cache.has(id)) {
      const url = this.endpoint(id);
      const response: AxiosResponse<T> = await Axios.get(url);
      this.cache.set(id, response.data);
    }

    return this.cache.get(id);
  }
}

export function fetchModel<ServerType extends {id: number}, ClientType extends Model<ServerType>>(
  endpoint: string,
  modelClass: ModelClass<ServerType, ClientType>
): (id) => Promise<ClientType> {
  const retriever = new ApiModelRetriever<ServerType, ClientType>(endpoint, modelClass);
  return retriever.fetch;
}

export class ApiModelRetriever<
  ServerType extends {id: number},
  ClientType extends Model<ServerType>
> {
  private endpoint: string;
  private modelClass: ModelClass<ServerType, ClientType>;
  private cache = new Map<number, Deferred<ClientType>>();

  constructor(endpoint: string, modelClass: ModelClass<ServerType, ClientType>) {
    this.endpoint = endpoint;
    this.modelClass = modelClass;
  }

  @autobind
  public async fetch(id: number): Promise<ClientType> {
    if (isNil(id)) {
      return null;
    }

    if (!this.cache.has(id)) {
      // Create promise first
      const promise = new Deferred<ClientType>();
      this.cache.set(id, promise);

      // Await response from server
      const url = `${this.endpoint}/${id}.json`;
      const response: AxiosResponse<ServerType> = await Axios.get(url);
      const model: ClientType = new this.modelClass(response.data);
      promise.resolve(model);
    }

    return this.cache.get(id);
  }

  @autobind
  public async fetchAll(ids: number[]): Promise<ClientType[]> {
    const notFetched: number[] = uniq(ids.filter((id) => !this.cache.has(id)));

    if (notFetched.length) {
      // Create promises for all queried IDs
      notFetched.forEach((id) => {
        this.cache.set(id, new Deferred<ClientType>());
      });

      // Execute search
      await this.search({id: notFetched} as any);
    }

    return await Promise.all(ids.map((id) => this.cache.get(id)));
  }

  @autobind
  public async search(params: SearchArgs<ServerType>): Promise<ClientType[]> {
    const filteredParams = omitBy(params, (val) => isEmpty(val) || isNil(val));

    if (isNil(filteredParams) || isEmpty(filteredParams)) {
      throw new Error('Must specify search criteria');
    }

    const responses: AxiosResponse<ServerType[]> = await Axios.get(this.endpoint, {
      params: filteredParams,
    });

    return Promise.all(
      responses.data.map((response: ServerType) => {
        const {id} = response;

        // Create cache entry if missing
        if (!this.cache.has(id)) {
          this.cache.set(id, new Deferred<ClientType>());
        }

        // Resolve cache entries
        const promise = this.cache.get(id);
        if (promise.pending) {
          const model: ClientType = new this.modelClass(response);
          promise.resolve(model);
        }

        return promise;
      })
    );
  }
}

export abstract class Model<ServerType> {
  public static find(id: number): Promise<Model<any>> {
    throw new Error('implement in subclasses');
  }

  protected data: ServerType;

  constructor(args: ServerType) {
    this.data = args;
  }
}
