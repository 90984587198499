// tslint:disable:no-unused-variable
import * as $ from 'jquery';
import * as d3 from 'd3';
import {cloneDeep, omit, max, map, some, isNumber, each, min} from 'lodash';
import {IFamilyTree, AncestryTree, DescendentTree, Partnership} from '../../../model/familyTree';

type TreeNode = d3.layout.tree.Node & {
  name: string;
  id?: number;
  url?: string;
  descendentDepth?: number;
  partnership?: true;
  partner?: {
    name: string;
    url: string;
    partnerNum: number;
  };
  partnerNum?: number;
  x0?: number;
  y0?: number;
};

type AncestryTreeNode = d3.layout.tree.Node & {
  name: string;
  url: string;
  id: number;
};

type DescendentTreeNode = d3.layout.tree.Node & {
  name: string;
  url: string;
  id: number;
  descendentDepth: number;
  children: DescendentTreePartnershipNode[];
};

type DescendentTreePartnershipNode = d3.layout.tree.Node & {
  name: string;
  descendentDepth: number;
  partnership: true;
  partner: {
    name: string;
    id: number;
    url: string;
    partnerNum: number;
  };
  children: DescendentTreeNode[];
};

type TreeParams = {
  vertical: boolean;
  switchDirection: boolean;
  xOffset?: number;
  yOffset?: number;
  maxLabelLength?: number;
  maxDepth: number;
};

const nodeColor = '#fff';
const collapsedNodeColor = 'lightsteelblue';
const linkColor = '#e6e2dd';

export function drawFamilyTree(container: SVGElement, data: IFamilyTree, onClick: (id: number) => void) {
  let svgGroup;
  let zoomListener;

  renderTree();

  function renderTree() {
    // Create svgGroup
    initializeTree();

    // Load + process data
    const ancestorTree: AncestryTreeNode = parentsToChildren(omit(data, 'children') as AncestryTree);
    const descendentTree: DescendentTreeNode = processDescendents(omit(data, 'parents') as DescendentTree, 0);

    // Layout settings
    const maxDepth: number = max([calculateDepth(ancestorTree), calculateDepth(descendentTree) / 2]);
    const vertical = false;
    const maxDescendentLabelLength: number = calculateMaxLabelLength(descendentTree) * 0.6;

    // Draw trees
    drawTree(descendentTree, {
      vertical,
      switchDirection: !vertical,
      maxLabelLength: maxDescendentLabelLength,
      maxDepth
    });
    drawPartners(descendentTree, vertical, maxDepth, maxDescendentLabelLength);
    drawTree(ancestorTree, {
      vertical,
      switchDirection: vertical,
      maxDepth
    });
    moveToFrontOfSvg(d3.select(container).selectAll('.node'));
  }

  /************* DATA HELPERS ************/

  function parentsToChildren(node: AncestryTree, depth: number = 0): AncestryTreeNode {
    return {
      name: node.name,
      id: node.id,
      url: node.url,
      children: map(node.parents, (child: AncestryTree) => parentsToChildren(child, depth + 1))
    };
  }

  function processDescendents(node: DescendentTree, depth: number): DescendentTreeNode {
    return {
      name: node.name,
      id: node.id,
      url: node.url,
      descendentDepth: depth,
      children: map(node.partners, (partnership: Partnership) => ({
        name: partnership.name,
        descendentDepth: depth + 0.5,
        partner: partnership.partner,
        partnership: true as true,
        children: map(partnership.children, (child: DescendentTree) => {
          return processDescendents(child, depth + 1);
        })
      }))
    };
  }

  function calculateMaxLabelLength(treeData) {
    const childrenLengths = map(treeData.children, calculateMaxLabelLength);
    const lengths = [treeData.name.length].concat(childrenLengths);
    return max(lengths);
  }

  function calculateDepth(tree, depth = 0) {
    if (Array.isArray(tree.children) && tree.children.length > 0) {
      return max(map(tree.children, (child) => {
        return calculateDepth(child, depth + 1);
      }));
    } else {
      return depth;
    }
  }

  /*************** SVG HELPERS ****************/

  function initializeTree() {
    // define the baseSvg, attaching a class for styling and the zoomListener
    const baseSvg = d3
      .select(container)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('class', 'overlay');

    // Append a group which holds all nodes and which the zoom Listener can act upon.
    svgGroup = baseSvg.append('g');

    // Define the zoom function for the zoomable tree
    function zoom() {
      const event = d3.event as d3.ZoomEvent;
      svgGroup.attr('transform', 'translate(' + event.translate + ')scale(' + event.scale + ')');
    }

    // define the zoomListener which calls the zoom function on the 'zoom' event constrained within the scaleExtents
    zoomListener = d3.behavior.zoom().scaleExtent([0.1, 3]).on('zoom', zoom);

    baseSvg.call(zoomListener);

    return svgGroup;
  }

  function drawTree(treeData: TreeNode, params: TreeParams) {
    // Process Params
    const switchDirection = params.switchDirection || false;
    const vertical = params.vertical || false;
    const xOffset = params.xOffset || 0;
    const yOffset = params.yOffset || 0;
    const maxLabelLength = params.maxLabelLength || calculateMaxLabelLength(treeData);
    const maxDepth = params.maxDepth || calculateDepth(treeData);

    // Initialize local vars
    const multiplier = switchDirection ? -1 : 1;
    const nodeGroup = svgGroup.append('g');

    // Misc. variables
    let i = 0;
    const duration = 750;
    let root;

    const viewerWidth = $(container).width();
    const viewerHeight = $(container).height();
    let tree: d3.layout.Tree<TreeNode> = d3.layout.tree().size([viewerHeight, viewerWidth]) as any;

    // define a d3 diagonal projection for use by the node paths later on.
    const diagonal = d3.svg.diagonal().projection((d) => {
      if (vertical) {
        return [d.x, d.y];
      } else {
        return [d.y, d.x];
      }
    });

    function englishName(d) {
      return d['english-name'] ? d['english-name'] : d.name;
    }

    // Helper functions for collapsing and expanding nodes.

    function collapse(d) {
      if (d.children) {
        d.children = d.children;
        d.children.forEach(collapse);
        d.children = null;
      }
    }

    function expand(d) {
      if (d.children) {
        d.children = d.hildren;
        d.children.forEach(expand);
        d.hildren = null;
      }
    }

    function centerNode(source) {
      const scale = zoomListener.scale();
      let x = -source.y0;
      let y = -source.x0;
      x = x * scale + viewerWidth / 2;
      y = y * scale + viewerHeight / 2;

      svgGroup
        .transition()
        .duration(duration)
        .attr('transform', 'translate(' + x + ',' + y + ')scale(' + scale + ')');
      // zoomListener.scale(scale);
      zoomListener.translate([x, y]);
    }

    // Toggle children function
    function toggleChildren(d: TreeNode) {
      if (d.children) {
        d.children = d.children;
        d.children = null;
      } else if (d.children) {
        d.children = d.children;
        d.children = null;
      }
      return d;
    }

    function click(d: TreeNode) {
      // Toggle children on click.
      const event: any = d3.event;
      if (event.defaultPrevented) {
        return; // click suppressed
      }
      // d = toggleChildren(d);
      // update(d);
      // centerNode(d);

      // Uncomment this for click redirect
      if (d.id) {
        onClick(d.id);
      }
    }

    function update(source) {
      // Compute the new height, function counts total children of root node and sets tree height accordingly.
      // This prevents the layout looking squashed when new nodes are made visible or looking sparse
      // when nodes are removed
      // This makes the layout more consistent.
      const levelWidth = [1];
      const childCount = (level, n) => {

        if (n.children && n.children.length > 0) {
          if (levelWidth.length <= level + 1) {
            levelWidth.push(0);
          }

          levelWidth[level + 1] += n.children.length;
          n.children.forEach((d) => {
            childCount(level + 1, d);
          });
        }
      };
      childCount(0, root);
      const newHeight = d3.max(levelWidth) * 70; // 70 pixels per line
      tree = tree.size([newHeight, viewerWidth]);

      // Compute the new tree layout.
      const nodes: TreeNode[] = tree.nodes(root).reverse();
      const links = tree.links(nodes);

      const rootNode = treeData;

      // Set widths between levels based on maxLabelLength.
      nodes.forEach((d: TreeNode) => {
        d.x = d.x - rootNode.x;
        const depth = isNumber(d.descendentDepth) ? d.descendentDepth : d.depth;

        if (vertical) {
          d.y = depth * (maxLabelLength * multiplier * 5);
        } else {
          d.y = depth * (maxLabelLength * multiplier * 8);
        }

        d.x += xOffset;
        d.y += yOffset;

        // For partner trees
        const upOrDown = (((d.partnerNum || 0) % 2) - 0.5) * 2;
        const maxDistance = 50;
        const minDistance = 25;
        const calcDistance = (maxDepth - depth - 1) * 7;
        const distance = min([max([minDistance, calcDistance]), maxDistance]);
        if (treeData.partnership && !d.partnership) {
          d.x += distance * upOrDown / 2;
        } else if (d.children && some(d.children, 'partnership') && d !== treeData) {
          d.x -= distance * upOrDown / 2;
        }
      });

      // Update the nodes…
      const node = nodeGroup.selectAll('g.node').data(nodes, (d) => {
        return d.id || (d.id = ++i);
      });

      // Enter any new nodes at the parent's previous position.
      const nodeEnter = node
        .enter()
        .append('g')
        .attr('class', 'node')
        .attr('transform', (d) => {
          if (vertical) {
            return 'translate(' + source.x0 + ',' + source.y0 + ')';
          } else {
            return 'translate(' + source.y0 + ',' + source.x0 + ')';
          }
        }).on('click', click);

      nodeEnter
        .filter((d) => {
          // Hide circle for partnership nodes
          return !d.partnership;
        })
        .append('circle')
        .attr('class', 'nodeCircle')
        .attr('r', 0)
        .style('fill', nodeColorFn);

      const textLabel = (d) => {
        if (d.partnership) {
          return '';
        } else {
          return englishName(d);
        }
      };

      if (vertical) {
        nodeEnter
          .append('text')
          .attr('y', (d) => {
            return d.children || d.hildren ? -18 : 18;
          })
          .attr('dy', '.35em')
          .attr('text-anchor', 'middle')
          .text(textLabel)
          .style('fill-opacity', 1);
      } else {
        nodeEnter
          .append('text')
          .attr('x', -20)
          .attr('dy', '.35em')
          .attr('class', 'nodeText')
          .attr('text-anchor', 'end')
          .text(textLabel)
          .style('fill-opacity', 0);
      }

      // append an image if one exists
      nodeEnter
        .append('image')
        .attr('title', '')
        .attr('xlink:href', '')
        .attr('x', -13)
        .attr('y', -13)
        .attr('width', 25)
        .attr('height', 25);

      // node.select('image').attr('xlink:href', function(d) {
      //   if (d.image)
      //     return d.image;
      //   else if (d.isFemale)
      //     return 'images/placeholder-female.png';
      //   else
      //     return 'images/placeholder.png';
      // });
      node.select('image').attr('title', (d) => {
        return '<strong>' + englishName(d) + '</strong>. ' + (d.bio ? d.bio : '');
      });

      // Update the text to reflect whether node has children or not.
      node
        .select('text')
        .attr('x', -20)
        .attr('text-anchor', 'end')
        .text(textLabel);

      // Change the circle fill depending on whether it has children and is collapsed
      node
        .select('circle.nodeCircle')
        .attr('r', 10)
        .style('fill', nodeColorFn);

      // Transition nodes to their new position.
      const nodeUpdate = node.transition().duration(duration).attr('transform', (d) => {
        if (vertical) {
          return 'translate(' + d.x + ',' + d.y + ')';
        } else {
          return 'translate(' + d.y + ',' + d.x + ')';
        }
      });

      // Fade the text in
      nodeUpdate.select('text').style('fill-opacity', 1);

      // Transition exiting nodes to the parent's new position.
      const nodeExit = node.exit().transition().duration(duration).attr('transform', (d) => {
        if (vertical) {
          return 'translate(' + source.x + ',' + source.y + ')';
        } else {
          return 'translate(' + source.y + ',' + source.x + ')';
        }
      }).remove();

      nodeExit.select('circle').attr('r', 0);

      nodeExit.select('text').style('fill-opacity', 0);

      // Update the links…
      const link = nodeGroup.selectAll('path.link').data(links, (d) => {
        return d.target.id;
      });

      // Enter any new links at the parent's previous position.
      link.enter()
        .insert('path', 'g')
        .attr('class', 'link')
        .attr('stroke-linecap', 'round')
        .style('stroke-width', (d) => {
          const depth = Math.floor(isNumber(d.source.descendentDepth) ? d.source.descendentDepth : d.source.depth);
          return 3 * (maxDepth - depth) + 'px';
        })
        .attr('d', (d) => {
          const o = {
            x: source.x0,
            y: source.y0
          };
          return diagonal({
            source: o,
            target: o
          });
        });

      // Transition links to their new position.
      link.transition().duration(duration).attr('d', diagonal);

      // Transition exiting nodes to the parent's new position.
      link
        .exit()
        .transition()
        .duration(duration)
        .attr('d', (d) => {
          const o = {
            x: source.x,
            y: source.y
          };
          return diagonal({
            source: o,
            target: o
          });
        }).remove();

      // Stash the old positions for transition.
      nodes.forEach((d) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    // Define the root -- this determines where the intital animation flows from
    root = treeData;
    root.x0 = viewerHeight / 2;
    root.y0 = viewerWidth / 2;

    // Layout the tree initially and center on the root node.
    update(root);
    centerNode(root);

    return nodeGroup[0][0];
  }

  function nodeColorFn(d) {
    if (d.partnership) {
      return linkColor;
    } else if (d.hildren) {
      return collapsedNodeColor;
    } else {
      return nodeColor;
    }
  }

  function moveToFrontOfGroup(svgElement) {
    const d3El = d3.select(svgElement);
    return d3El.each(function() {
      if (this.parentNode) {
        this.parentNode.appendChild(this);
      }
    });
  }

  function moveToFrontOfSvg(els) {
    return els.each(function() {
      svgGroup[0][0].appendChild(this);
    });
  }

  function drawPartners(
    tree: DescendentTreeNode,
    vertical: boolean,
    maxDepth: number,
    maxLabelLength: number) {
    each(tree.children, (partnership: DescendentTreePartnershipNode) => {
      if (!partnership.partner) {
        // If other parent is unknown, don't draw
        return;
      }

      const partnershipNode: DescendentTreePartnershipNode = {
        name: partnership.name,
        partnership: true,
        partner: partnership.partner,
        children: [{
          ...partnership.partner,
          children: [],
          descendentDepth: 0.5
        } as DescendentTreeNode],
        descendentDepth: 0
      };

      // Draw this partnership
      drawTree(partnershipNode, {
        vertical,
        switchDirection: vertical,
        xOffset: partnership.x,
        yOffset: partnership.y,
        maxLabelLength,
        maxDepth: maxDepth - partnership.descendentDepth
      });

      each(partnership.children, (child) => {
        drawPartners(child, vertical, maxDepth, maxLabelLength);
      });
    });
  }
}