// tslint:disable:member-ordering
import {autobind} from 'core-decorators';

import Location from './location';
import Person from './person';
import {Model, ApiModelRetriever} from './model';

type ServerEvent = {
  id: number;
  location_id: number;
  person_id: number;
  type: 'Birth' | 'Death' | 'Residence' | 'Divorce' | 'Wedding';
  description: string;
  date: string;
  created_at: string;
  updated_at: string;
  date_approximate: boolean;
};

@autobind
export default class Event extends Model<ServerEvent> {
  private static retriever = new ApiModelRetriever<ServerEvent, Event>('/api/events', Event);
  public static find = Event.retriever.fetch;
  public static findAll = Event.retriever.fetchAll;

  public get id(): number {
    return this.data.id;
  }

  public get date(): Date {
    const {date} = this.data;

    if (date) {
      const utcDate = Date.parse(date);
      const tzOffset = new Date(date).getTimezoneOffset() * 60 * 1000;
      return new Date(utcDate + tzOffset);
    }
  }

  public get dateApproximate(): boolean {
    return this.data.date_approximate;
  }

  public get locationId(): number {
    return this.data.location_id;
  }

  public get location(): Promise<Location> {
    return Location.find(this.data.location_id);
  }

  public get type(): string {
    return this.data.type;
  }

  public get personId(): number {
    return this.data.person_id;
  }

  public get person(): Promise<Person> {
    return Person.find(this.data.person_id);
  }

  public get description(): string {
    return this.data.description;
  }

  public get verb(): string {
    switch (this.type) {
      case('Adoption'):
        return 'Adopted';
      case('Birth'):
        return 'Born';
      case('Death'):
        return 'Died';
      case('Divorce'):
        return 'Divorced';
      case('Wedding'):
        return 'Married';
      case('Residence'):
        return 'Moved';
      default:
        return this.type;
    }
  }

  public get updatedAt(): Date {
    return new Date(this.data.updated_at);
  }

  public get createdAt(): Date {
    return new Date(this.data.created_at);
  }
}
