import * as React from 'react';
import Person from '../../model/person';
import {AsyncPeopleMap} from '../../components/maps/peopleMap';
import {Icon} from 'react-fa';

interface FamilyHistoryPeopleMapProps {
  personId: number;
}

interface FamilyHistoryPeopleMapState {
  people: Person[];
  loading: boolean;
}

export default class FamilyHistoryPeopleMap extends React.Component<FamilyHistoryPeopleMapProps, FamilyHistoryPeopleMapState> {
  public state: FamilyHistoryPeopleMapState = {
    people: [],
    loading: true
  };

  public async componentDidMount() {
    const center = await Person.find(this.props.personId);

    // Two degrees of removal -- include cousins
    const people = await center.family(2);
    this.setState({people}, () => {
      this.setState({loading: false});
    });
  }

  public render(): React.ReactElement<any> {
    return (
      <div className='family-history-people-map-container'>
        {this.loadingIndicator}
        <AsyncPeopleMap people={this.state.people}/>
      </div>
    );
  }

  private get loadingIndicator(): React.ReactElement<any> {
    if (this.state.loading) {
      return (
        <div className='loading-indicator'>
          <Icon name='spinner' spin={true}/>
          {' '}
          Loading family data...
        </div>
      );
    }
  }
}