// tslint:disable:member-ordering
import * as React from 'react';
import {autobind} from 'core-decorators';
import Joyride, {Step, Locale} from 'react-joyride';
import User from '../../../model/user';

@autobind
export class PeopleMapTour extends React.Component<{}, {
  user: User;
  autoStart: boolean;
  run: boolean;
}> {
  private mounted: boolean;

  constructor(props, context) {
    super(props, context);
    this.mounted = false;
    this.state = {
      user: null,
      run: false,
      autoStart: false
    };
  }

  public async componentDidMount() {
    this.mounted = true;
    const user: User = await User.currentUser();
    if (!user.hasSeenTimelineMapTour) {
      setTimeout(() => {
        if (this.mounted) {
          this.setState({
            user,
            autoStart: true,
            run: true
          });
        }
      }, 500);
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactElement<any> {
    if (!this.state.user) {
      return null;
    }

    return (
      <div className='people-map-tour-container'>
        <Joyride
          steps={this.steps}
          run={this.state.run} // or some other boolean for when you want to start it
          autoStart={this.state.autoStart}
          type='continuous'
          debug={false}
          locale={this.locale}
          callback={this.joyrideCallback}
        />
      </div>
    );
  }

  private joyrideCallback(params: any) {
    const {type} = params;

    const finished: boolean = (type === 'error:target_not_found') || (type === 'finished');

    if (finished && this.state.user) {
      this.state.user.update({timeline_map_tour: true});
    }
  }

  private locale: Locale = {
    back: 'Back',
    close: 'Close',
    last: 'Done',
    next: 'Next',
    skip: 'Skip'
  };

  private steps: Step[] = [{
    selector: '.auto-scaling-map',
    position: 'right',
    text: 'This is the timeline map.  It shows the movement of your family over time.'
  }, {
    selector: '.map-link',
    text: 'Every person has their own timeline map, showing their ancestry. ' +
          "You can access a person's map by clicking this button."
  }, {
    selector: '.date-slider',
    text: 'This slider controls the year of the map. ' +
          'Try clicking different points on the line to see your ancestors at various times.',
    allowClicksThruHole: true
  }, {
    selector: '.play-btn',
    text: 'Press the play button to watch the map advance through time.',
    allowClicksThruHole: true
  }, {
    selector: '.leaflet-marker-icon',
    text: 'When playback is paused you can click any marker to view information on that person. ' +
          'The pop-up links to their profile.',
    allowClicksThruHole: true
  }];
}
