import Axios, {AxiosResponse} from 'axios';
import {autobind} from 'core-decorators';

import {Model} from './model';
import csrfToken from './csrfToken';

type ServerUser = {
  id: number;
  email: string;
  timeline_map_tour: boolean;
  created_at: string;
  updated_at: string;
  person_id: number;
};

@autobind
export default class User extends Model<ServerUser> {
  public static async currentUser(): Promise<User> {
    const response: AxiosResponse<ServerUser> = await Axios.get('/api/user');
    return new User(response.data);
  }

  public get id(): number {
    return this.data.id;
  }

  public get hasSeenTimelineMapTour(): boolean {
    return this.data.timeline_map_tour;
  }

  public async update(user: Partial<ServerUser>): Promise<void> {
    await Axios.put('/api/user', {user}, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken
      }
    });
  }

  public get updatedAt(): Date {
    return new Date(this.data.updated_at);
  }

  public get createdAt(): Date {
    return new Date(this.data.created_at);
  }
}
