// tslint:disable
import * as $ from 'jquery';
import 'select2';
import { ServerPerson } from '../model/person';

type SearchPerson = ServerPerson & {
  relationship_to_current_user?: string;
}

function initializeSelect2() {
  $('select.person-select')
    .not('[data-select2-id]')
    .select2({
      theme: 'bootstrap',
      ajax: {
        url: '/people/search',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            query: params.term,
            gender: $(this).data('gender'),
          };
        },
        processResults: function (data) {
          return {
            results: data,
          };
        },
        cache: true,
      },
      minimumInputLength: 1,
      escapeMarkup: function (markup) {
        return markup;
      },
      dropdownAutoWidth: true,
      templateResult: function (state: SearchPerson) {
        let name = '';
        let preferredName = '';
        let aliases = '';
        let relationship = '';
        if (state.name) {
          name = state.name;
        }

        if (state.preferred_name) {
          preferredName = `<div class="small"> (${state.preferred_name})</div>`;
        }

        if (state.aliases) {
          aliases = state.aliases
            .map((alias: string) => `<div class="small"> (${alias}) </div>`)
            .join('');
        }

        if (state.relationship_to_current_user) {
          var formattedRelationship = state.relationship_to_current_user.replace(
            /\^(\d+)/,
            '<sup>$1</sup>'
          );

          relationship = '<div class="relationship-name">' + formattedRelationship + '</div>';
        }

        return '<div>' + name + preferredName + aliases + relationship + '</div>';
      },
      templateSelection: function (state: any) {
        return state.text || state.name;
      },
    });

  $('select.location-select')
    .not('[data-select2-id]')
    .each((i, el) => {
      $(el).select2({
        theme: 'bootstrap',
        ajax: {
          url: '/locations/search',
          dataType: 'json',
          delay: 250,
          data: function (params) {
            return {
              query: params.term,
              types: $(el).data('query-type'),
            };
          },
          processResults: function (data) {
            var results = data.predictions.map(function (p) {
              return {
                id: p.description,
                text: p.description,
              };
            });

            return {
              results: results,
            };
          },
          cache: true,
        },
        minimumInputLength: 1,
      });
    });

  $('.nav-person-select').on('change', function () {
    var personId = $(this).val();
    window.location.href = '/people/' + personId;
  });
}

$(document).on('turbolinks:load', initializeSelect2);
$(document).on('cocoon:after-insert', initializeSelect2);
