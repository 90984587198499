
import * as Turbolinks from 'turbolinks';
Turbolinks.start();

// JavaScript
import './cocoon';
import './event_edit';
import './person_form';
import './select';

// Styles
import './application.scss';
import './people.scss';