import {autobind} from 'core-decorators';

import {Model, fetchModel} from './model';

type ServerDocument = {
  id: number;
  name: string;
  file_file_name: string;
  file_content_type: string;
  file_file_size: number;
  file_updated_at: string;
  url: string;
  created_at: string;
  updated_at: string;
};

@autobind
export default class Document extends Model<ServerDocument> {
  public static find = fetchModel('/api/documents', Document);

  public get id(): number {
    return this.data.id;
  }

  public get name(): string {
    return this.data.name;
  }

  public get fileName(): string {
    return this.data.file_file_name;
  }

  public get url(): string {
    return this.data.url;
  }

  public get updatedAt(): Date {
    return new Date(this.data.updated_at);
  }

  public get createdAt(): Date {
    return new Date(this.data.created_at);
  }
}
