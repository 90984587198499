import Panzoom from '@panzoom/panzoom'
import * as React from 'react';
import Person from '../../../model/person';
import {Graph, PersonNode} from './graph';
import {Point, PointMap, Bounds, Edge, StressorType, StressorGroups} from './pointMap';
import {Button} from 'reactstrap';
import {autobind} from 'core-decorators';

export interface SvgGraphProps {
  centerPersonId: number;
  people: Person[];
}

interface SvgGraphState {
  pointMap: PointMap;
  animating: boolean;
  bounds: Bounds;
  orientation: Orientation;
}

enum Orientation {
  vertical,
  horizontal
}

@autobind
export class SvgGraph extends React.Component<SvgGraphProps, SvgGraphState> {
  private svgElement: SVGElement;
  private svgWidth: number = 500;
  private svgHeight: number = 500;
  private animationInterval: NodeJS.Timer;

  constructor(props: SvgGraphProps, context) {
    super(props, context);
    const graph: Graph = new Graph(props.people);
    const pointMap: PointMap = new PointMap(graph, props.centerPersonId.toString());

    // 2: stronger as they get closer
    // 1: constant force at any distance
    // 0.5: stronger as they get further

    const stressorGroups: StressorGroups = {
      siblings: {
        type: StressorType.Above,
        strength: 0.000,
        curve: 2
      },
      family: {
        type: StressorType.Attracted,
        strength: 0.1,
        curve: 0.2 // gravity
      },
      generation: {
        type: StressorType.Repelled,
        strength: 0.001,
        curve: 1.4
      },
      parents: {
        type: StressorType.Above,
        strength: 0.000,
        curve: 2
      },
      physics: {
        momentum: 0,
        minDistance: 0.01,
        maxForce: 1
      }
    };

    pointMap.setStressors(stressorGroups);
    const {bounds} = pointMap.draw();

    this.state = {
      pointMap,
      animating: false,
      bounds,
      orientation: Orientation.horizontal
    };
  }

  public componentDidMount() {
    this.updateAnimationState();
    window.addEventListener('resize', this.updateDimensions);
    Panzoom(this.svgElement);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public componentDidUpdate(prevProps: SvgGraphProps, prevState: SvgGraphState) {
    this.updateAnimationState();
  }

  private updateAnimationState() {
    if(this.animationInterval && !this.state.animating) {
      clearInterval(this.animationInterval);
      this.animationInterval = null;
    } else if (!this.animationInterval && this.state.animating) {
      this.animationInterval = setInterval(() => {
        this.state.pointMap.tick();
        this.forceUpdate();
      }, 50);
    }
  }

  public render() {
    const {nodes, edges, bounds} = this.state.pointMap.draw();

    return (
      <div className='svg-graph'>
        <Button onClick={this.toggleAnimation}>{this.state.animating ? 'Pause' : 'Play'}</Button>
        <svg ref={this.svgRef}>
          {edges.map((edge: Edge) => this.renderEdge(edge))}
          {nodes.map((point: Point) => this.renderPoint(point))}
        </svg>
      </div>
    );
  }

  private toggleAnimation() {
    const {animating} = this.state;
    this.setState({
      animating: !animating
    });
  }

  private svgRef(ref: SVGElement) {
    this.svgElement = ref;
    this.updateDimensions();
  }

  private updateDimensions() {
    this.svgWidth = this.svgElement.clientWidth;
    this.svgHeight = this.svgElement.clientHeight;
  }

  private tick() {
    this.state.pointMap.tick();
    this.forceUpdate();
  }

  private renderPoint(point: Point): React.ReactNode {
    const onClick = () => {
      const node: PersonNode = point.node as PersonNode;
      console.log(node.name, point);
    }

    const {x, y} = this.pointCoords(point);

    // Opacity is based on generational distance (fade out the past)
    // Saturation is based on proximity to direct lineage
    // Hue is based on maternal / paternal division
    const style: React.CSSProperties = {
      fill: point.node.key === this.props.centerPersonId.toString()
        ? 'red'
        : undefined
    };

    return <circle style={style} r={5} cx={x} cy={y} key={point.node.key} onClick={onClick} />;
  }

  private renderEdge(edge: Edge): React.ReactNode {
    const {orientation} = this.state;
    const [source, target] = edge;
    const {x: startX, y: startY} = this.pointCoords(source);
    const {x: endX, y: endY} = this.pointCoords(target);
    const midX = (endX + startX) / 2;
    const midY = (endY + startY) / 2;
    const path = [
      // Start point
      `M${startX} ${startY}`,
      // Curve to midpoint
      orientation === Orientation.vertical
        ? `Q${startX} ${midY}, ${midX} ${midY}`
        : `Q${midX} ${startY}, ${midX} ${midY}`,
      // Curve to target
      `T${endX} ${endY}`
    ].join(' ');
    return <path key={`${source.node.key}-${target.node.key}`} d={path}/>;
  }

  private pointCoords(point: Point): {x: number, y: number} {
    const {bounds, orientation} = this.state;
    const {minX, maxX, minGen, maxGen} = bounds;
    const xPercent = (point.x - bounds.minX) / (maxX - minX);
    const yPercent = (point.generation - bounds.minGen) / (maxGen - minGen);

    switch(orientation) {
      case(Orientation.vertical):
        return {
          x: xPercent * this.svgWidth,
          y: (1 - yPercent) * this.svgHeight
        };
      case(Orientation.horizontal):
        return {
          x: yPercent * this.svgWidth,
          y: xPercent * this.svgHeight
        };
    }
  }
}