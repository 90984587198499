import * as React from 'react';
import * as moment from 'moment';
import {compact, maxBy, mean} from 'lodash';

import TimelineMap, {TimelineMapUnit, TimelineMapEvent} from '../timelineMap';
import {defaultMarkerIconImg} from '../mapHelpers';
import Location from '../../../model/location';

export type MappableEvent = {
  key: string | number;
  verb: string;
  date: Date;
  location: Location;
};

export type MappablePerson = {
  id: number;
  name: string;
  birth: MappableEvent;
  death: MappableEvent;
  events: MappableEvent[];
};

export default class PeopleMap extends React.Component<{
  people: MappablePerson[];
}, {}> {

  public render(): React.ReactElement<any> {
    return (
      <TimelineMap mapUnits={this.mapUnits} />
    );
  }

  // More likely that death was unrecorded than that someone was > 120 years old
  private makeABunchOfAssumptionsAboutAgeAndDeath(birthDate: Date): Date {
    if (birthDate && birthDate.getTime() < moment().subtract(120, 'years').toDate().getTime()) {
      return moment(birthDate).add(120, 'years').toDate();
    }
  }

  private get mapUnits(): TimelineMapUnit[] {
    const {people} = this.props;
    const rangedPeople = people.filter((p: MappablePerson) => {
      return p.birth && p.birth.date;
    });

    return rangedPeople.map((person: MappablePerson) => {
      const {birth, death} = person;
      const birthDate = birth && birth.date ? new Date(birth.date) : null;
      const deathDate = death && death.date
        ? new Date(death.date)
        // Assume death after 120 years
        : this.makeABunchOfAssumptionsAboutAgeAndDeath(birthDate);

      let lifespan: string;
      if (birthDate && deathDate) {
        lifespan = `${birthDate.getFullYear()} – ${deathDate.getFullYear()}`;
      } else if (birthDate) {
        lifespan = `Born in ${birthDate.getFullYear()}`;
      } else if (deathDate) {
        lifespan = `Passed in ${deathDate.getFullYear()}`;
      }

      const name = compact([
        person.name,
        lifespan ? `(${lifespan})` : null
      ]).join(' ');

      const randomRepeatableHue = Math.floor(Math.sin(person.id) * 360);

      return {
        name,
        key: person.id,
        icon: defaultMarkerIconImg,
        hue: randomRepeatableHue,
        url: `/people/${person.id}`,
        dateRange: {
          start: birthDate,
          end: deathDate
        },
        events: generateMappableEvents(person)
      };
    });
  }
}

function eventToTimelineMapEvent(event: MappableEvent): TimelineMapEvent {
  const {location} = event;
  if (location && location.latitude && location.longitude && event.date) {
    return {
      key: event.key,
      title: event.verb,
      date: new Date(event.date),
      location
    };
  }
}

// If there is no residence record saying when a person moved, use their date location
// to add one
function generateMappableEvents(person: MappablePerson): TimelineMapEvent[] {
  const {death, events} = person;

  const mappableEvents: TimelineMapEvent[] = events.map(eventToTimelineMapEvent).filter(Boolean);

  // Simulate a move midway between the last known residency and place of death
  // This is so the map unit does not move + disappear in the same tick
  if (death && eventToTimelineMapEvent(death) && mappableEvents.length) {
    const lastEvent = maxBy(mappableEvents, (e) => e.date);
    const midlife = new Date(mean([death.date.getTime(), lastEvent.date.getTime()]));

    mappableEvents.push({
      key: 'finalMove',
      title: 'Moved',
      date: midlife,
      location: death.location
    });
  }

  return mappableEvents;
}