// tslint:disable:no-unused-variable
import * as React from 'react';
import {autobind} from 'core-decorators';
import {IFamilyTree} from '../../../model/familyTree';
import {drawFamilyTree} from './d3Tree';

@autobind
export default class FamilyTreeGraph extends React.Component<{
  treeData: IFamilyTree;
  onClickNode?: (id: number) => void;
}, {}> {
  private container: SVGElement;

  public componentDidMount() {
    if (this.container) {
      drawFamilyTree(this.container, this.props.treeData, this.onClickNode);
    }
  }

  public componentDidUpdate() {
    drawFamilyTree(this.container, this.props.treeData, this.onClickNode);
  }

  public render(): React.ReactElement<any> {
    return (
      <div className='family-tree-container'>
        <svg ref={this.refFn}/>
      </div>
    );
  }

  private onClickNode(id: number) {
    const {onClickNode} = this.props;
    if (onClickNode) {
      onClickNode(id);
    } else {
      location.href = `/people/${id}/tree`;
    }
  }

  private refFn(r: SVGElement) {
    this.container = r;
  }
}
