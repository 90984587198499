import * as React from 'react';
import {autobind} from 'core-decorators';

import {SvgGraph} from './svgGraph';
import PromiseRenderer from '../../util/promiseRenderer';
import Person from '../../../model/person';

interface SvgGraphLoaderProps {
  personId: number;
}

interface SvgGraphLoaderState {
  family: Promise<Person[]>;
}

@autobind
export class SvgGraphLoader extends React.Component<SvgGraphLoaderProps, SvgGraphLoaderState> {
  constructor(props: SvgGraphLoaderProps, context) {
    super(props, context);
    this.state = {
      family: this.familyPromise(props.personId)
    }
  }

  private async familyPromise(personId: number): Promise<Person[]> {
    const person: Person = await Person.find(personId);
    const family: Promise<Person[]> = person.family(1);
    return family;
  }

  public render(): React.ReactElement<any> {
    const {family} = this.state;

    return (
      <PromiseRenderer promise={family} renderer={this.graphRenderer}/>
    );
  }

  private graphRenderer(people: Person[]): React.ReactElement<any> {
    return (
      <SvgGraph centerPersonId={this.props.personId} people={people}/>
    );
  }
}