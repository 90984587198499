import {Graph, PersonNode, PartnershipNode, NodeType} from './graph';
import { groupBy, each } from 'lodash';
import Person from '../../../model/person';

export interface Point {
  node: PersonNode | PartnershipNode;
  generation: number; // Generational position. Fixed value.
  x: number; // Position within generation
  dx: number; // Velocity
}

export interface Bounds {
  minGen: number;
  maxGen: number;
  minX: number;
  maxX: number;
}

export type Edge = [Point, Point];

interface Stressor {
  source: Point;
  target: Point;
  params: StressorParams;
}

export interface StressorParams {
  type: StressorType;
  curve: number; // does force square or cube with distance?
  strength: number; // constant coefficient
}

export interface StressorGroups {
  // Intra-generations stressors
  siblings: StressorParams;
  parents: StressorParams;
  generation: StressorParams;

  // Cross-generation stressors
  family: StressorParams;

  physics: Physics;
}

export interface Physics {
  // 0: no momentum
  // 1: maintain momentum
  momentum: number;

  // Prevent atomic reactions
  minDistance: number;

  // maxForce
  maxForce: number;
}

export enum StressorType {
  Attracted,
  Repelled,
  Above
}

export class PointMap {
  public readonly points = new Map<string, Point>();
  private readonly stressors = new Set<Stressor>();
  private physics: Physics;

  constructor(graph: Graph, centerPointKey: string) {
    const person = graph.personNodes.get(parseInt(centerPointKey));
    const partnership = graph.partnershipNodes.get(centerPointKey);
    if(person) {
      this.initializePerson(person, 0, 0);
    } else if(partnership) {
      this.initializePartnership(partnership, 0, 0);
    } else {
      throw new Error(`Graph did not contain key ${centerPointKey}`);
    }
  }

  // Convert internal state into list of nodes, edges, and boundaries for drawing
  public draw(): {nodes: Point[], edges: Edge[], bounds: Bounds} {
    const edges: Edge[] = [];
    const nodes: Point[] = [];

    const randomPoint: Point = this.points.values().next().value;
    const bounds: Bounds = {
      minX: randomPoint.x,
      maxX: randomPoint.x,
      minGen: randomPoint.generation,
      maxGen: randomPoint.generation
    };

    this.points.forEach((point: Point) => {
      const {node} = point;
      if(node.type === NodeType.Person) {
        nodes.push(point);
      } else if (node.type === NodeType.Partnership) {
        const {mother, father, children} = node;
        const targetPoints: Point[] = [
          mother && this.points.get(node.mother.key),
          father && this.points.get(node.father.key),
          ...children.map((c) => this.points.get(c.key))
        ].filter(Boolean);
        if(targetPoints.length >= 2) {
          edges.push(...targetPoints.map((target: Point): Edge => (
            [point, target]
          )));
        }
      }

      if(point.x < bounds.minX) {
        bounds.minX = point.x;
      } else if (point.x > bounds.maxX) {
        bounds.maxX = point.x;
      }

      if(point.generation < bounds.minGen) {
        bounds.minGen = point.generation;
      } else if (point.generation > bounds.maxGen) {
        bounds.maxGen = point.generation;
      }
    });

    return {
      nodes,
      edges,
      bounds: {
        ...bounds,
        // minX: 0,
        // maxX: 1
      }
    };
  }

  public setStressors(stressorGroups: StressorGroups) {
    const {siblings, parents, family, generation, physics} = stressorGroups;
    this.stressors.clear();

    this.points.forEach((point: Point) => {
      if(point.node.type === NodeType.Partnership) {
        const mother = point.node.mother && this.points.get(point.node.mother.key);
        const father = point.node.father && this.points.get(point.node.father.key);
        const children = point.node.children.map((c) => this.points.get(c.key));

        // Stressor between parents
        if(mother && father) {
          this.stressors.add({
            source: mother,
            target: father,
            params: parents
          });
        }

        // Stressor between siblings
        for(let i = 0; i < children.length; i++) {
          for(let j = i + 1; j < children.length; j++) {
            this.stressors.add({
              source: children[i],
              target: children[j],
              params: siblings
            });
          }
        }

        // Stressor between partnerships and all nodes
        const targets: Point[] = [
          mother,
          father,
          ...children
        ].filter(Boolean);

        targets.forEach((target: Point) => {
          this.stressors.add({
            source: point,
            target,
            params: family
          });
        })
      }
    });

    // How nodes within a generation affect each other
    const generationGroups: {[generation: number]: Point[]} = groupBy(Array.from(this.points.values()), (p) => p.generation);
    each(generationGroups, (pointsInGeneration: Point[]) => {
      for(let i = 0; i < pointsInGeneration.length; i++) {
        for(let j = i + 1; j < pointsInGeneration.length; j++) {
          this.stressors.add({
            source: pointsInGeneration[i],
            target: pointsInGeneration[j],
            params: generation
          });
        }
      }
    });

    this.physics = physics;
  }

  public tick() {
    const {maxForce, minDistance, momentum} = this.physics;

    this.stressors.forEach((stressor: Stressor) => {
      const {source, target, params} = stressor;
      const {type, strength, curve} = params;
      const xDistance = target.x - source.x;
      const genDistance = target.generation - source.generation;
      const distance = Math.max(Math.sqrt(Math.pow(xDistance, 2) + Math.pow(genDistance, 2)), minDistance);
      const force = strength / Math.pow(distance, curve);
      const angle = Math.asin(genDistance / distance);
      const forceX = Math.min(maxForce, Math.abs(Math.cos(angle) * force));

      const [left, right] = source.x < target.x
        ? [source, target]
        : [target, source];

      switch(type) {
        case(StressorType.Attracted):
          left.dx += forceX;
          right.dx -= forceX;
          break;
        case(StressorType.Repelled):
          left.dx -= forceX;
          right.dx += forceX;
          break;
        case(StressorType.Above):
          // Source wants to be left of target
          source.dx -= forceX;
          target.dx += forceX;
          break;
      }
    });

    this.points.forEach((point: Point) => {
      point.x += point.dx;
      // if(point.x < 0) {
      //   point.x = 0;
      //   point.dx = 0;
      // } else if (point.x > 1) {
      //   point.x = 1;
      //   point.dx = 0;
      // } else {
        point.dx *= momentum;
      // }
    });
  }

  private initializePerson(personNode: PersonNode, generation: number, x: number) {
    const key: string = personNode.key;
    if(this.points.has(key)) {
      return;
    } else {
      this.points.set(key, {
        node: personNode,
        generation,
        x,
        dx: 0
      });
    }

    this.initializePartnership(personNode.parents, generation + 1, x);
    personNode.partnerships.forEach((partnership: PartnershipNode) => {
      this.initializePartnership(partnership, generation, x);
    });
  }

  private initializePartnership(partnershipNode: PartnershipNode, generation: number, x: number) {
    const key: string = partnershipNode.key;
    if(this.points.has(key)) {
      return;
    } else {
      this.points.set(key, {
        node: partnershipNode,
        generation: generation - .5,
        x,
        dx: 0
      });
    }

    const generationCoefficient = Math.pow(2, Math.abs(Math.ceil(generation)));

    const {mother, father, children} = partnershipNode;
    if(mother) {
      this.initializePerson(mother, generation, x - 1 / generationCoefficient);
    }

    if(father)  {
      this.initializePerson(father, generation, x + 1 / generationCoefficient);
    }

    if(children) {
      children.forEach((child: PersonNode, i: number) => {
        this.initializePerson(child, generation - 1, x + (Math.random() - .5) * 2 / generationCoefficient);
      });
    }
  }
}
