import {autobind} from 'core-decorators';

import {Model, fetchModel} from './model';

type ServerImage = {
  id: number;
  name: string;
  file_file_name: string;
  file_content_type: string;
  file_file_size: number;
  file_updated_at: string;
  urls: {
    full: string;
    medium: string;
    thumb: string;
  };
  created_at: string;
  updated_at: string;
};

@autobind
export default class Image extends Model<ServerImage> {
  public static find = fetchModel('/api/images', Image);

  public get id(): number {
    return this.data.id;
  }

  public get name(): string {
    return this.data.name;
  }

  public get fullImageUrl(): string {
    return this.data.urls.full;
  }

  public get mediumImageUrl(): string {
    return this.data.urls.medium;
  }

  public get thumbImageUrl(): string {
    return this.data.urls.thumb;
  }

  public get updatedAt(): Date {
    return new Date(this.data.updated_at);
  }

  public get createdAt(): Date {
    return new Date(this.data.created_at);
  }
}
