import * as React from 'react';
import {autobind} from 'core-decorators';

import FamilyTreeGraph from './familyTreeGraph';
import PromiseRenderer from '../../util/promiseRenderer';
import FamilyTree, {IFamilyTree} from '../../../model/familyTree';

interface AsyncFamilyTreeProps {
  personId: number;
  vertical?: boolean;
  onClickNode?: (personId: number) => void;
}

interface AsyncFamilyTreeState {
  treePromise: Promise<IFamilyTree>;
}

@autobind
export default class AsyncFamilyTree extends React.Component<AsyncFamilyTreeProps, AsyncFamilyTreeState> {
  constructor(props: AsyncFamilyTreeProps, context) {
    super(props, context);
    this.state = {
      treePromise: FamilyTree.find(props.personId)
    }
  }

  public componentDidUpdate(prevProps: AsyncFamilyTreeProps) {
    if(this.props.personId !== prevProps.personId) {
      const treePromise: Promise<IFamilyTree> = FamilyTree.find(this.props.personId);
      this.setState({treePromise});
    }
  }

  public render(): React.ReactElement<any> {
    return (
      <PromiseRenderer promise={this.state.treePromise} renderer={this.treeRenderer}/>
    );
  }

  private onClick(personId: number) {
    const {onClickNode} = this.props;

    if (onClickNode) {
      onClickNode(personId);
    } else if (personId !== this.props.personId) {
      location.href = `/people/${personId}/tree`;
    }
  }

  private treeRenderer(tree: IFamilyTree) {
    return (
      <FamilyTreeGraph treeData={tree} onClickNode={this.onClick} />
    );
  }
}