import * as React from 'react';
import {Button} from 'reactstrap';
import {autobind} from 'core-decorators';
import {Icon} from 'react-fa';

import {TimelineMapUnit} from './timelineMap';

@autobind
export class TimelineMapLegend extends React.Component<{
  activeMapUnits: TimelineMapUnit[];
}, {
  collapse: boolean;
}> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      collapse: true
    };
  }

  public render(): React.ReactElement<any> {
    const element = this.state.collapse
      ? (
        <Button onClick={this.expand}>
          <Icon name='expand'/>
          {' '}
          Legend
        </Button>
      ) : (
        <ul className='timeline-map-legend list-unstyled' onClick={this.collapse}>
          {this.props.activeMapUnits.map((unit: TimelineMapUnit) => (
            <li key={unit.key}>
              <div className={'d-inline-block'}>
                <div style={{filter: `hue-rotate(${unit.hue}deg`}}>
                  {React.cloneElement(unit.icon, {style: {}})}
                </div>
              </div>
              {' '}
              {unit.name}
            </li>
          ))}
        </ul>
      );

    return (
      <div className='timeline-map-legend'>
        {element}
      </div>
    );
  }

  private expand() {
    this.setState({collapse: false});
  }

  private collapse() {
    this.setState({collapse: true});
  }
}